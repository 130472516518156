import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    cart :{
      items:[]
    },
    planter:[],
    carbon_cart:{
      emit_mats:[], //emitter materials list
      emit_mans:[], //emitter materials manufacture list
      emit_stage:[],
      //emit_elec:[],
    },
    carbon_ca: {
      emit_area:[]
    },
    ground_floor_area:'',
    total_floor_area: '',

    carbon_cart_electricity:0,
    carbon_cart_distance:0,
    carbon_manufacture_total:0,
    username:'',
    isAuthenticated: false,
  
    access: '',
    refresh: '',

    name:'',
    email:'',
    phone_contact:'',
    company_name:'',
    mobilisation_cost:'',
    location_name:'',
    location_distance:'',
    total_quantity: 0,
    cement_quantity: 0,
    stone_quantity:0,
    cement_total_cost:0,
    stone_total_cost:0,

    cement_II_quantity: 0,
    cement_cem1_total:0,
    cement_cem2_total:0,
    cement_II_total:0,
    cement_II_total_cost:0,

    invoice_number:'',

    //New Carbon Footprint
    carbon_cement: '',
    carbon_sand: '',
    carbon_stone: '',
    ground_floor_area:'',
    total_floor_area: '',
    nearest_stone_quarry_distance: '',

    stone_quarries: [ "-0.79219,29.94999","-0.53343,30.65854","-0.57738,30.66358",
                      "0.61782,30.14634","0.60260,30.22324","2.79659,32.32246",
                      "2.286296,32.940203","3.258741,32.129553","0.436825,32.378539",
                      "0.687199,34.185503","1.142958,34.229428","0.407657,32.555487",
                      "0.344222,32.485748","0.3814074,32.636789","0.3167686,32.620611",
                      "0.5016663,32.705516"],

    isLoading: false,
    products:[],
    materials:[],
    cement_price:[],
    stone_dust_price:[],
    emitter_materials:[],
    emitter_stages:[],
    material_manufactures:[],
    carbon_site_location:[],
    concrete_class:[
      {
        name: 'C20',
        cement: 1,
        sand: 2,
        stone: 4
      },
      {
        name: 'C25',
        cement: 1,
        sand: 2,
        stone: 3
      },
      {
        name: 'C30',
        cement: 1,
        sand: 1.5,
        stone: 3
      }
    ]
  },
  getters: {
    total_amount_company(state){
      return state.total_amount_company
    },
    products(state){
      return state.products;
    },
    cement_price(state){
      return state.cement_price
    },
    stone_dust_price(state){
      return state.stone_dust_price
    },
    emitter_materials(state){
      return state.emitter_materials
    },
    emitter_stages(state){
      return state.emitter_stages
    },
    material_manufactures(state){
      return state.material_manufactures
    }

  },
  mutations: {
    initializeStore(state){
      if (localStorage.getItem('cart')){
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('carbon_cart')){
        state.carbon_cart = JSON.parse(localStorage.getItem('carbon_cart'))
      } else {
        localStorage.setItem('carbon_cart', JSON.stringify(state.carbon_cart))
      }

      if(localStorage.getItem('refresh')){
        state.refresh = localStorage.getItem('refresh')
      }else{
        state.refresh = ''
      }
      if(localStorage.getItem('access')){
          state.access = localStorage.getItem('access')
          //state.refresh = localStorage.getItem('refresh')
          state.isAuthenticated = true
      }else{
          state.access = ''
          //state.refresh = ''
          state.isAuthenticated = false
      }
      if(localStorage.getItem('username')){
          state.username = localStorage.getItem('username')
          state.isAuthenticated = true
      }else{
          state.token = ''
          state.isAuthenticated = false
      }

      if(localStorage.getItem('name')){
        state.name = localStorage.getItem('name')
      }else{
        state.name =''
      }
      if(localStorage.getItem('email')){
        state.email = localStorage.getItem('email')
      }else{
        state.email =''
      }
      if(localStorage.getItem('phone_contact')){
        state.phone_contact = localStorage.getItem('phone_contact')
      }else{
        state.phone_contact =''
      }
      if(localStorage.getItem('company_name')){
        state.company_name = localStorage.getItem('company_name')
      }else{
        state.company_name =''
      }
      /*
      if(localStorage.getItem('total_quantity')){
        state.total_quantity = localStorage.getItem('total_quantity')
      }else{
        state.total_quantity=''
      }*/
    },

    //New Auth
    setAuth(state, auth){
      state.isAuthenticated = auth
    },
    setUser(state, user){
      state.username = user
    },

    //Carbon Footprint
    setGroundFloorArea(state, ground_floor_area){
      state.ground_floor_area = ground_floor_area
    },
    setTotalFloorArea(state, total_floor_area){
      state.total_floor_area = total_floor_area
    },
    setCarbonCement(state, carbon_cement){
      state.carbon_cement = carbon_cement
    },
    setCarbonSand(state, carbon_sand){
      state.carbon_sand = carbon_sand
    },
    setCarbonStone(state, carbon_stone){
      state.carbon_stone = carbon_stone
    },
    setNearestQuarryDistance(state, nearest_stone_quarry_distance){
      state.nearest_stone_quarry_distance = nearest_stone_quarry_distance
    },

    //Authentication
    setAccess(state, access){
      state.access = access
      state.isAuthenticated = true
    },
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    setUsername(state, username){
      state.username = username
      state.isAuthenticated = true
      localStorage.setItem('username', state.username)
    },
    removeAccess(state) {
      state.access = ''
      state.isAuthenticated = false
    },

    setPlanter(state, planter){
      state.planter = planter
    },
    setCementCem1Total(state, cement_cem1_total){
      state.cement_cem1_total = cement_cem1_total
    },
    setCementCem2Total(state, cement_cem2_total){
      state.cement_cem2_total = cement_cem2_total
    },
    setProducts(state, products){
      state.products = products
    },
    setEmitterMaterials(state, emitter_materials){
      state.emitter_materials = emitter_materials
    },
    setEmitterStages(state, emitter_stages){
      state.emitter_stages = emitter_stages
    },
    setMaterialManufacture(state, material_manufactures){
      state.material_manufactures = material_manufactures
    },
    setCementPrice(state, cement_price){
      state.cement_price = cement_price
    },
    setStoneDustPrice(state, stone_dust_price){
      state.stone_dust_price = stone_dust_price
    },
    setToken(state, token){
      state.token = token
      state.isAuthenticated = true
    },
    
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    removeUsername(state){
      state.username = ''
      state.isAuthenticated = false
    },
    setName(state, name){
      state.name = name
    },
    setEmail(state, email){
      state.email = email
    },
    setPhone(state, phone_contact){
      state.phone_contact = phone_contact
    },
    setCompany(state, company_name){
      state.company_name = company_name
    },
    setMobilisationCost(state, mobilisation_cost){
      state.mobilisation_cost = mobilisation_cost
    },
    setLocationName(state, location_name){
      state.location_name = location_name
    },
    setLocationDistance(state, location_distance){
      state.location_distance = location_distance
    },
    setTotalQuantity(state, total_quantity){
      state.total_quantity = total_quantity
    },
    setCementQuantity(state, cement_quantity){
      state.cement_quantity = cement_quantity
    },
    setStoneQuantity(state, stone_quantity){
      state.stone_quantity = stone_quantity
    },
    setCementTotalCost(state, cement_total_cost){
      state.cement_total_cost = cement_total_cost
    },
    setCementIITotalCost(state, cement_II_total_cost){
      state.cement_II_total_cost = cement_II_total_cost
    },
    setStoneTotalCost(state, stone_total_cost){
      state.stone_total_cost = stone_total_cost
    },
    setCarbonSiteLocation(state, carbon_site_location){
      state.carbon_site_location = carbon_site_location
    },
    setCarbonCartElectricity(state, carbon_cart_electricity){
      state.carbon_cart_electricity = carbon_cart_electricity
    },
    setCarbonCartDistance(state, carbon_cart_distance){
      state.carbon_cart_distance = carbon_cart_distance
    },
    setCarbonManufactureTotal(state, carbon_manufacture_total){
      state.carbon_manufacture_total = carbon_manufacture_total
    },

    setInvoiceNumber(state, invoice_number){
      state.invoice_number = invoice_number
    },

    addToCart(state, item){
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length){
        exists[0].quantity = /*parseInt(exists[0].quantity)*/ parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }
    },

    //Mutation for adding material for distance computations
    addToCarbonCartMat(state, item){
      const exists = state.carbon_cart.emit_mats.filter(i => i.emitter_material.id === item.emitter_material.id)
      if (exists.length){
        exists[0].number_of_trips = parseInt(item.number_of_trips)
        exists[0].distance = parseInt(item.distance)
        exists[0].fuel_efficiency = parseInt(item.fuel_efficiency)
        exists[0].mode = parseInt(item.mode)
      } else {
        state.carbon_cart.emit_mats.push(item)
      }
    },

    //Mutation for adding materials used for manufacturing computations
    addToCarbonCartMans(state, item){
      const exists = state.carbon_cart.emit_mans.filter(i => i.material_manufacture.id === item.material_manufacture.id)
      if (exists.length){
        exists[0].amount_of_material = parseInt(item.amount_of_material)
      } else {
        state.carbon_cart.emit_mans.push(item)
      }
    },

    //Mutation for adding emissions due to construction operations
    addToCarbonCartStage(state, item){
      const exists = state.carbon_cart.emit_stage.filter(i => i.emitter_stage.id === item.emitter_stage.id)
      if (exists.length){
        exists[0].number_of_litres = parseInt(item.number_of_litres)
        exists[0].emission_factor = parseInt(item.emission_factor)
      } else {
        state.carbon_cart.emit_stage.push(item)
      }
    },

    setIsLoading(state, status){
      state.isLoading = status
    },
    clearCart(state){
      state.cart = { items: [] }
      localStorage.setItem('cart', JSON.stringify(state.cart))
      state.total_quantity = 0
  },
  clearCarbonCart(state){
    state.carbon_cart = {
       emit_mans:[],
       emit_mats:[],
       emit_stages:[],
      }
    localStorage.setItem('carbon_cart', JSON.stringify(state.carbon_cart))

  },
  clearTotal(state){
    state.cart = { items: [] }
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.total_quantity = 0
    
  }
  },
  actions: {
    fetchAllProducts(context){
      return axios.get('/api/v1/products/')
                  .then(response => {
                    context.commit("setProducts", response.data)
                  })
                  .catch((err) => console.error(err));
    },
    fetchAllMaterials(context){
      return axios.get('api/v1/emitter_materials/')
                .then(response => {
                    context.commit("setEmitterMaterials", response.data)
                  })
                .catch((err) => console.error(err));
    },
    fetchAllStages(context){
      return axios.get('api/v1/emitter_stages/')
                .then(response => {
                  console.log(response.data)
                  context.commit("setEmitterStages", response.data)
                })
              .catch((err) => console.error(err));
    },
    fetchAllMaterialManufacture(context){
      return axios.get('api/v1/material_manufacture/')
                  .then(response => {
                    console.log(response.data)
                    context.commit("setMaterialManufacture", response.data)
                  })
                  
                  .catch((err) => console.error(err))
    },
    fetchCementPrice(context){
      return axios.get('/api/v1/materials/1/')
                  .then(response => {
                    context.commit("setCementPrice", response.data.price)
                  })
                  .catch((err) => console.error(err));
                },
    fetchStoneDustPrice(context){
      return axios.get('/api/v1/materials/2/')
                  .then(response => {
                    context.commit("setStoneDustPrice", response.data.price)
                  })
                  .catch((err) => console.error(err));
      
    },
    addToTotal(context, payload){
      const total_quantity = context.state.total_quantity
      total_quantity += payload
      context.commit('UPDATE_TOTAL', total_quantity)
    }
  },

  modules: {
  }
})
