import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import Cart from '../views/Cart.vue'
import PostInfo from '../views/PostInfo.vue'
import Register from '../views/Register.vue'
import IndexView from '../views/IndexView.vue'
import PortFolio from '../views/PortFolio.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import DocumentView from '../views/DocumentView.vue'
import CarbonIndex from '../views/CarbonIndex.vue'
import CarbonIndex2 from '../views/CarbonIndex2.vue'
import CarbonResults from '../views/CarbonResults.vue'
import CarbonR from '../views/CarbonR.vue'
import ResetEmail from '../views/ResetEmail.vue'
import ResetConfirm from '../views/ResetConfirm.vue'
import PathNotFound from '../views/PathNotFound.vue'
import KigeziView from '@/views/KigeziView.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/materials-calculator',
    name: 'home',
    component: HomeView,
  
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/results',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/postinfo',
    name: 'PostInfo',
    component: PostInfo,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('../views/PortFolio.vue'),
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
  },
  {
    path: '/reset-email',
    name: 'ResetEmail',
    component: ResetEmail,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetConfirm',
    component: ResetConfirm,
  },
  {
    path: '/documents',
    name: 'DocumentView',
    component: DocumentView,
  },
  {
    path:'/privacy-policy',
    name: 'Policy',
    component: () => import('../views/Policy.vue'),
  },
  {
    path: '/carbon-footprint-calculator',
    name: 'CarbonIndex2',
    component: CarbonIndex2
  },
  {
    path: '/carbon-footprint-results',
    name: 'CarbonResults',
    component: CarbonResults
  },
  {
    path: '/carbon-results',
    name: 'CarbonR',
    component: CarbonR
  },
  {
    path: '/:pathMatch(.*)*',
    component: PathNotFound
  },
  {
    path: '/kigezi-cement',
    component: () => import('../views/KigeziView.vue'),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } })
  } else {
    next()
  }
})




export default router
